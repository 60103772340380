<template>
    <div class="datasoures">
        <el-row style="padding:20px">
            <el-col :span="3">
                <div @click="handFromshow"><el-input
                class="dev_dowm"
                placeholder="请点击查询"
                suffix-icon="el-icon-arrow-down"
                :readonly="true"
                >
                </el-input></div>
            </el-col>
        </el-row>
        <div class="fromshea" v-show="iShow">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="企业名称">
                    <el-select v-model="formInline.region" placeholder="活动区域">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="部门">
                    <el-select v-model="formInline.region" placeholder="活动区域">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="formInline.user" placeholder="审批人"></el-input>
                </el-form-item>

                <el-form-item label="工号">
                   <el-input v-model="formInline.user" placeholder="审批人"></el-input>
                </el-form-item>
                <el-form-item label="手机号码">
                    <el-input v-model="formInline.user" placeholder="审批人"></el-input>
                </el-form-item>
                <el-form-item label="卡号">
                    <el-input v-model="formInline.user" placeholder="审批人"></el-input>
                </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
        </div>
        <Tables :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :operation="operation" :configs="configs"></Tables>
        <!-- <Selfauditdiolg ref="selfauditdiolg"></Selfauditdiolg> -->
    </div>
</template>
<script>
import Tables from '@/components/table'
import Tabheader from './components/heaerbutom'
// import Selfauditdiolg from './components/selfauditdiolg.vue'
export default {
    components:{
        Tables,
        Tabheader,
        // Selfauditdiolg
    },
    data(){
        return{
            iShow:false,
            formInline: {
                user: '',
                region: ''
            },
            input:'',
            centerDialogVisible: false,
            tableLabel: [
                {
                    prop: 'name',
                    label: '姓名',
                    align: 'center'
                },
                {
                    prop: 'xuliehao',
                    label: '企业部门',
                    align: 'center'
                },
                {
                    prop: 'status',
                    label: '性别',
                    align: 'center'
                },
                {
                    prop: 'kaimstatus',
                    label: '手机号码',
                    align: 'center'
                },
                {
                    prop: 'kaimoshi',
                    label: '证据号码',
                    align: 'center'
                },
                {
                    prop: 'rlnumber',
                    label: '卡号',
                    align: 'center'
                },
                {
                    prop: 'rlnumber1',
                    label: '人像照片',
                    align: 'center'
                }
            ],
            tableData: [
                {
                  name: '张三',
                  xuliehao: '五金一厂生产部',
                  status: {
                      name:'男',
                      state:true,
                      type:'success'
                  },
                  kaimstatus: '1358****1234',
                  kaimoshi: '25000********89601',
                  rlnumber: '1065893102',
                  rlnumber1: {
                      name:'预览',
                      but:true,
                      type:'success'
                  },
                }
            ],
            flags: {
                show: true, // 多选是否显示
                ishow: true // 序号是否显示
            },
            // 分页数据
            configs: {
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
            operation: {
                width:'200px',
                data:[
                {
                    name: '坏卡补卡',
                    type: 'danger'
                  }
                ]
            },
        }
    },
    methods:{
        handRegister(){
            this.$refs.selfauditdiolg.centerDialogVisible=true
        },
        handFromshow(){
            this.iShow = !this.iShow 
        },
        onSubmit() {
            console.log('submit!');
        }
    }
}
</script>
<style lang="less" scoped>
.datasoures{
    padding: 20px;
    background-color: #fff;
    .fromshea{
        margin-top:20px;
        padding: 20px;
    }
    .shear{
        display: flex;
        span{
            
        }
    }
}
.name{
    flex: 2;
    line-height: 30px;
}
.tbut{
    margin-left: 20px;
}
</style>